import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home-partner',
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: './home-partner.component.html',
  styleUrl: './home-partner.component.scss',
})
export class HomePartnerComponent {
  companySlides: OwlOptions = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    smartSpeed: 2000,
    autoplay: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
}
