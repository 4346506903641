<div class="newsletter">
  <div class="container">
    <div class="newsletter-area">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="newsletter-text">
            <h3>Suscríbete a nuestro boletín</h3>
            <p>
              Su boletín informativo puede brindar un gran valor, más allá de
              las ventas, al informar a sus compradores con contenido
              interesante que resuene con ellos.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <form class="newsletter-form">
            <input
              type="email"
              class="form-control"
              placeholder="escriba su correo"
              name="EMAIL"
              required
              autocomplete="off"
            />
            <button class="default-btn electronics-btn" type="submit">
              Suscribirse.
            </button>
          </form>
        </div>
      </div>
      <div class="subscribe-shape">
        <img
          src="../../../../assets/img/shape/subscribe-shape.png"
          alt="shape"
        />
        <img
          src="../../../../assets/img/shape/subscribe-shape-two.png"
          alt="shape"
        />
        <img
          src="../../../../assets/img/shape/subscribe-shape-three.png"
          alt="shape"
        />
      </div>
    </div>
  </div>
</div>
