<div class="trusted-company ptb-100">
  <div class="container">
    <div class="section-title text-center">
      <h2>Hemos trabajado con</h2>
      <p>
        Las alianzas estratégicas son la clave para el éxito de cualquier
        empresa.
      </p>
    </div>
    <div class="company-slider">
      <owl-carousel-o [options]="companySlides">
        <ng-template carouselSlide>
          <a href="#" target="_blank">
            <img
              src="../../../../assets/img/sass-landing/company/1.png"
              alt="company logo"
            />
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" target="_blank">
            <img
              src="../../../../assets/img/sass-landing/company/2.png"
              alt="company logo"
            />
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" target="_blank">
            <img
              src="../../../../assets/img/sass-landing/company/3.png"
              alt="company logo"
            />
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" target="_blank">
            <img
              src="../../../../assets/img/sass-landing/company/4.png"
              alt="company logo"
            />
          </a>
        </ng-template>
        <ng-template carouselSlide>
          <a href="#" target="_blank">
            <img
              src="../../../../assets/img/sass-landing/company/3.png"
              alt="company logo"
            />
          </a>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
