import { Component } from '@angular/core';

@Component({
  selector: 'app-home-showcase',
  standalone: true,
  imports: [],
  templateUrl: './home-showcase.component.html',
  styleUrl: './home-showcase.component.scss'
})
export class HomeShowcaseComponent {

}
