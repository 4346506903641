<div id="showcase" class="show-case-section pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Nuestros últimos trabajos</h2>
      <p>
        Una muestra de los trabajos que hemos realizado para nuestros clientes y
        la variedad de servicios que ofrecemos.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="showcase-card">
          <img
            src="../../../../assets/img/personal-portfolio/showcase/1.png"
            alt="showcase image"
          />
          <div class="showcsae-text">
            <h3>Reconstrucción</h3>
            <p>
              Ver como renace, retoma su brillo y su explendor una instalación
              de mas de un año es una experiencia única.
            </p>
          </div>
          <div class="shoscase-hover">
            <div class="d-table">
              <div class="d-table-cell">
                <h3>Reconstrucción</h3>
                <p>
                  Cada sección fue desmontada, limpiada, reparada y vuelta a
                  montar. Las partes mas dañadas fueron reemplazadas por otras
                  nuevas. El resultado final fue una instalación impecable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="showcase-card">
          <img
            src="../../../../assets/img/personal-portfolio/showcase/2.png"
            alt="showcase image"
          />
          <div class="showcsae-text">
            <h3>Montaje</h3>
            <p>
              Cada local tiene su propia personalidad, su propia esencia. El
              reto es, sin perder la esencia, darle un toque de modernidad.
            </p>
          </div>
          <div class="shoscase-hover">
            <div class="d-table">
              <div class="d-table-cell">
                <h3>Montaje</h3>
                <p>
                  Se adaptó la instalación a la arquitectura del local, se hizo
                  parte de la decoración, dándole un retoque a la decoración del
                  local. El resultado final fue una instalación que se veía como
                  parte de la decoración original del lugar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="showcase-card">
          <img
            src="../../../../assets/img/personal-portfolio/showcase/3.png"
            alt="showcase image"
          />
          <div class="showcsae-text">
            <h3>Techo doble</h3>
            <p>
              El estilo de la arquitectura debía ser respetado. El resultado fue
              una instalación integrada armónicamente con su entorno.
            </p>
          </div>
          <div class="shoscase-hover">
            <div class="d-table">
              <div class="d-table-cell">
                <h3>Techo doble</h3>
                <p>
                  No solo es el talento, la habilidad y la experiencia de los
                  expertos, es también su buen gusto y su capacidad de
                  adaptación a las necesidades del cliente. La belleza de la
                  instalación y la limpieza del trabajo hacen que para muchos
                  sea una obra de arte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="showcase-card">
          <img
            src="../../../../assets/img/personal-portfolio/showcase/4.png"
            alt="showcase image"
          />
          <div class="showcsae-text">
            <h3>Revisión periódica</h3>
            <p>
              Con cierta frecuencia es necesario hacer una revisión de la
              instalación para asegurarse de que todo está en orden.
            </p>
          </div>
          <div class="shoscase-hover">
            <div class="d-table">
              <div class="d-table-cell">
                <h3>Revisión periódica</h3>
                <p>
                  Las revisiones periódicas son necesarias para mantener la
                  instalación en buen estado y solucionar a tiempo cualquier
                  problema que se presente. La revisión periódica es una de las
                  tareas mas importantes que se deben realizar. Problema
                  detectado a tiempo es dinero ahorrado al cliente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="showcase-card">
          <img
            src="../../../../assets/img/personal-portfolio/showcase/5.png"
            alt="showcase image"
          />
          <div class="showcsae-text">
            <h3>Limpieza y mantenimiento</h3>
            <p>
              La limpieza y el mantenimiento son tareas que no se pueden dejar
              de hacer. Prolongan la vida útil de la instalación.
            </p>
          </div>
          <div class="shoscase-hover">
            <div class="d-table">
              <div class="d-table-cell">
                <h3>Limpieza y mantenimiento</h3>
                <p>
                  Estas dos tareas deben hacerse con personal calificado que
                  conozca la instalación y sepa como hacerlo. Es crucial
                  devolverle la funcionalidad y la belleza a la instalación.
                  Cada detalle cuenta. Un matenimiento efectivo reduce los
                  costos de reparación y prolonga la vida útil de la
                  instalación.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="showcase-card">
          <img
            src="../../../../assets/img/personal-portfolio/showcase/6.png"
            alt="showcase image"
          />
          <div class="showcsae-text">
            <h3>Estructura compleja</h3>
            <p>
              Una hermosa propiedad con una estructura compleja que requiere de
              un acabado exquisito en la instalación.
            </p>
          </div>
          <div class="shoscase-hover">
            <div class="d-table">
              <div class="d-table-cell">
                <h3>Estructura compleja</h3>
                <p>
                  La instalación fue un reto para los expertos, pero el
                  resultado final fue una obra maestra. La instalación se veía
                  como parte de la estructura original de la propiedad. La
                  belleza de la instalación y la limpieza del trabajo hacen que
                  para muchos sea una obra de arte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
