<app-navbar></app-navbar>
<app-home-banner></app-home-banner>
<app-home-about></app-home-about>
<app-home-services></app-home-services>
<app-home-tools></app-home-tools>
<app-home-showcase></app-home-showcase>
<app-home-partner></app-home-partner>
<app-home-testimonials></app-home-testimonials>
<app-home-contact></app-home-contact>
<app-newsletter></app-newsletter>
<app-footer></app-footer>
<ngx-scrolltop></ngx-scrolltop>
