<div id="software" class="software-section pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Materiales que usamos</h2>
      <p>
        Tan importante como el conocimiento los materiales que se usan para
        llevar a cabo el proyecto.
      </p>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="software-img">
          <img
            *ngIf="currentTab === 'tab1'"
            src="../../../../assets/img/personal-portfolio/tools.jpg"
            alt="software image"
          />
          <img
            *ngIf="currentTab === 'tab2'"
            src="../../../../assets/img/personal-portfolio/cobre.jpg"
            alt="software image"
          />
          <img
            *ngIf="currentTab === 'tab3'"
            src="../../../../assets/img/personal-portfolio/inoxidable.jpeg"
            alt="software image"
          />
          <div class="software-img-text">
            <img
              src="../../../../assets/img/personal-portfolio/icon-1.png"
              alt="photoshop icon"
            />
            <p>Precisión, eficacia, efectividad</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="software-text">
          <ul class="nav-tabset">
            <li class="nav-tab" [ngClass]="{ active: currentTab === 'tab1' }">
              <span (click)="switchTab($event, 'tab1')"> Galvanizado </span>
            </li>
            <li class="nav-tab" [ngClass]="{ active: currentTab === 'tab2' }">
              <span (click)="switchTab($event, 'tab2')"> Cobre </span>
            </li>
            <li class="nav-tab" [ngClass]="{ active: currentTab === 'tab3' }">
              <span (click)="switchTab($event, 'tab3')"> Inoxidable </span>
            </li>
          </ul>
          <div class="tabs-container">
            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
              <h2>Acero galvanizado</h2>
              <p>
                El acero galvanizado ofrece resistencia parcial a la corrosión y
                a los químicos sin aumentar el precio en cuanto a la calidad del
                producto. El acero galvanizado es un acero recubierto de zinc,
                que se realiza mediante un proceso de inmersión en caliente. Una
                vez sumergido, el acero está prácticamente protegido de los
                efectos del medió exterior y ambiental, viéndose afectado a un
                ritmo muy lento.
              </p>
              <a
                routerLink="/portfolio-landing"
                (click)="onClick('pricing')"
                class="software-link"
                >Habla con nosotros!</a
              >
            </div>
            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
              <h2>Cobre</h2>
              <p>
                De todos los materiales de los cuales pueden hacerse las
                canaletas de lluvia, el cobre es uno de los favoritos,
                especialmente a la hora de invertir, pues resulta extremadamente
                duradero y resistente al óxido; no necesita decoración ni
                mantenimiento, excepto para remover residuos. Si escoges el
                cobre como material para tus canaletas de lluvia, quizás nunca
                necesites cambiarlas.
              </p>
              <a
                routerLink="/portfolio-landing"
                (click)="onClick('pricing')"
                class="software-link"
                >Habla con nosotros!</a
              >
            </div>
            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
              <h2>Acero inoxidable</h2>
              <p>
                Este material comparte muchas cosas en común con el acero
                galvanizado, pero este material es considerablemente más
                resistente a la corrosión y dura mucho más tiempo sin oxidarse.
                La estructura de las canaletas de acero inoxidable mantiene su
                solidez y brillo a pesar de los fuertes cambios de temperatura,
                un rasgo que se realza con una limpieza regular. El peso es
                similar al de las canaletas de acero galvanizado, pero su
                durabilidad las hace mucho más caras.
              </p>
              <a
                routerLink="/portfolio-landing"
                (click)="onClick('pricing')"
                class="software-link"
                >Habla con nosotros!</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="software-shape">
    <img src="../../../../assets/img/shape/home-shape.png" alt="shape" />
    <img src="../../../../assets/img/shape/home-shape-four.png" alt="shape" />
    <img src="../../../../assets/img/shape/home-shape-two.png" alt="shape" />
    <img
      src="../../../../assets/img/shape/subscribe-shape-three.png"
      alt="shape"
    />
  </div>
</div>
