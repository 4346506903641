import { Component, OnInit, inject } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { FooterComponent } from '../../shared/footer/footer.component';
import { WINDOW1 } from '../../shared/windows_token';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NewsletterComponent } from '../../shared/newsletter/newsletter.component';
import { HomeBannerComponent } from '../../components/home-banner/home-banner.component';
import { HomeAboutComponent } from '../../components/home-about/home-about.component';
import { HomeServicesComponent } from '../../components/home-services/home-services.component';
import { HomeToolsComponent } from '../../components/home-tools/home-tools.component';
import { HomeShowcaseComponent } from '../../components/home-showcase/home-showcase.component';
import { HomePartnerComponent } from '../../components/home-partner/home-partner.component';
import { HomeTestimonialsComponent } from '../../components/home-testimonials/home-testimonials.component';
import { HomeContactComponent } from '../../components/home-contact/home-contact.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    FooterComponent,
    NewsletterComponent,
    NgxScrollTopModule,
    HomeBannerComponent,
    HomeAboutComponent,
    HomeServicesComponent,
    HomeToolsComponent,
    HomeShowcaseComponent,
    HomePartnerComponent,
    HomeTestimonialsComponent,
    HomeContactComponent,
  ],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  location: any;
  routerSubscription: any;
  window = inject(WINDOW1);

  constructor(private router: Router) {}

  ngOnInit() {}
  afterViewInit() {
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        this.window.scrollTo(0, 0);
      });
  }
}
