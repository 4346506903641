import { Component } from '@angular/core';

@Component({
  selector: 'app-home-services',
  standalone: true,
  imports: [],
  templateUrl: './home-services.component.html',
  styleUrl: './home-services.component.scss'
})
export class HomeServicesComponent {

}
