import { Component } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home-testimonials',
  standalone: true,
  imports: [CarouselModule],
  templateUrl: './home-testimonials.component.html',
  styleUrl: './home-testimonials.component.scss',
})
export class HomeTestimonialsComponent {
  testimonialTextSlides: OwlOptions = {
    loop: true,
    margin: 10,
    nav: true,
    navText: [
      "<i class='flaticon-left'></i>",
      "<i class='flaticon-right'></i>",
    ],
    dots: false,
    smartSpeed: 2000,
    items: 1,
  };
}
