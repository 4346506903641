<div id="home" class="main-banner banner-style-four">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-text">
              <span>Hola! Somos</span>
              <h1>MB RAIN GUTTER SYSTEMS, L.L.C</h1>
              <h6>Expertos en hacer tu vida mas fácil</h6>
              <p>
                Contamos con los mejores especialistas, las mejores herramientas
                y los mejores materiales para asumir su proyecto. Después de
                terminado el proyecto no lo abandonamos, le ofrecemos los
                mejores planes de mantenimiento.
              </p>
              <div class="banner-btn">
                <a routerLink="/portfolio-landing" (click)="onClick('pricing')"
                  >Contáctanos!</a
                >
                <a
                  routerLink="/portfolio-landing"
                  class="cv-btn"
                  (click)="onClick('showcase')"
                  >Nuestro trabajo</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1">
            <div class="banner-img-two">
              <img
                src="../../../../assets/img/personal-portfolio/home_about2.jpg"
                alt="iphone"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="banner-shape">
    <img src="../../../../assets/img/shape/home-shape.png" alt="shape" />
    <img src="../../../../assets/img/shape/home-shape-two.png" alt="shape" />
    <img src="../../../../assets/img/shape/home-shape-four.png" alt="shape" />
  </div>
</div>
