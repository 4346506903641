import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewportScroller } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-home-about',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-about.component.html',
  styleUrl: './home-about.component.scss',
  animations: [
    trigger('growShrink', [
      state('small', style({ height: '50px' })),
      state('large', style({ height: '200px' })),
      transition('small <=> large', animate('300ms ease-in-out')),
    ]),
  ],
})
export class HomeAboutComponent {
  constructor(private viewportScroller: ViewportScroller) {}

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
