<div id="testimonials" class="testimonial-style-three pb-100">
  <div class="container">
    <div class="section-title">
      <h2>Clientes opinan sobre nosotros</h2>
      <p>
        Cada criterio, opinión y recomendación de nuestros clientes es muy
        valiosa para nosotros.
      </p>
    </div>
  </div>
  <div class="testimonial-area">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-sm-10 offset-sm-1"
        >
          <div class="testimonial-text-slider slider-for text-center">
            <owl-carousel-o [options]="testimonialTextSlides">
              <ng-template carouselSlide>
                <div class="sin-testiText">
                  <img
                    src="../../../../assets/img/personal-portfolio/testimonial/1.png"
                    alt="client image"
                  />
                  <h2>Martin Miller</h2>
                  <span>Contador</span>
                  <p>
                    Estoy muy contento con el trabajo que realizó el equipo de
                    trabajo. Cumplieron con todas mis expectativas y en tiempo
                    récord. Recomiendo a estos profesionales para cualquier
                    trabajo.
                  </p>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="sin-testiText">
                  <img
                    src="../../../../assets/img/personal-portfolio/testimonial/2.png"
                    alt="client image"
                  />
                  <h2>Anthony Grand</h2>
                  <span>Ejecutivo</span>
                  <p>
                    Quedé impresionado con toda la ejecución del proyecto. El
                    equipo de trabajo es muy profesional y cumplió con todas mis
                    expectativas. Les doy un 10.
                  </p>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="sin-testiText">
                  <img
                    src="../../../../assets/img/personal-portfolio/testimonial/3.png"
                    alt="client image"
                  />
                  <h2>Alfred Alma</h2>
                  <span>Diseñador</span>
                  <p>
                    Al principio tuve mis dudas pero al ver el resultado final
                    estoy súper contento.
                  </p>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="sin-testiText">
                  <img
                    src="../../../../assets/img/personal-portfolio/testimonial/4.png"
                    alt="client image"
                  />
                  <h2>Eddey López</h2>
                  <span>Chofer</span>
                  <p>
                    El trabajo en mi casa era complicado por la ubicación y el
                    clima pero el equipo de trabajo lo hizo sin problemas. Quedé
                    mas que satisfecho.
                  </p>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="sin-testiText">
                  <img
                    src="../../../../assets/img/personal-portfolio/testimonial/5.png"
                    alt="client image"
                  />
                  <h2>William Muller</h2>
                  <span>Director músical</span>
                  <p>
                    Al principio pensé que estaban locos por todo el proyecto
                    que me mostraron y los tiempos que decían pero no solo
                    ejecutaron el proyecto que tenían planificado sino que
                    también cumplieron con los plazos. Fue increíble.
                  </p>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
