import { InjectionToken } from '@angular/core';

export const WINDOW1 = new InjectionToken<Window>('WindowToken', {
  factory: () => {
    if (typeof window !== 'undefined') {
      return window;
    }
    return new Window(); // does this work?
  },
});

export const WINDOW = new InjectionToken<Window>('WINDOW', {
  factory: () => (typeof window !== 'undefined' ? window : ({} as Window)),
});
