<div id="about" class="about-section pt-100 pb-70">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-4 p-0">
        <div class="about-img wow fadeInUp" data-wow-duration="1s">
          <img
            src="../../../../assets/img/personal-portfolio/myself.png"
            alt="about image"
          />
          <div class="about-img-text">
            <p>MB RAIN GUTTER SYSTEMS, L.L.C</p>
            <ul>
              <li>
                <a href="#" target="_blank"
                  ><i
                    class="flaticon-facebook"
                    style="background-color: white"
                  ></i
                ></a>
              </li>
              <li>
                <a href="#" target="_blank"
                  ><i
                    class="flaticon-twitter"
                    style="background-color: white"
                  ></i
                ></a>
              </li>
              <li>
                <a href="#" target="_blank"
                  ><i
                    class="flaticon-behance"
                    style="background-color: white"
                  ></i
                ></a>
              </li>
              <li>
                <a href="#" target="_blank"
                  ><i class="flaticon-globe" style="background-color: white"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="about-text wow fadeInUp" data-wow-duration="1s">
          <h2>Quienes somos</h2>
          <p>
            Somos MB RAIN GUTTER SYSTEMS, L.L.C, una pequeña empresa radicada en
            el estado de La Florida. Nos especializamos en la instalación de
            canales de agua para los techos de cualquier tipo de local o
            vivienda. Concertamos con usted un proyecto ajustado a su
            presupuesto, cumpliendo las normas legales establecidas en el estado
            de La Florida y los estándares de calidad mas exigentes. Trabajamos
            para usted, por usted y con usted. Ponemos a su disposición mas de 7
            años de experiencia.
          </p>
          <ul>
            <li><i class="flaticon-email"></i>mbinf&#64;gmail.com</li>
            <li>
              <i class="flaticon-maps-and-flags"></i>28/A Street, New York, USA
            </li>
            <li><i class="flaticon-call"></i>+13028580567</li>
            <li><i class="flaticon-globe"></i>www.mbdomain.com</li>
            <li><i class="flaticon-flag"></i>North American</li>
            <li><i class="flaticon-work-from-home"></i>Disponible 24 horas</li>
          </ul>
          <div class="about-btn">
            <a routerLink="#" (click)="onClick('pricing')">Contrátanos!</a>
            <a routerLink="/portfolio-landing" (click)="onClick('showcase')"
              >Nuestro trabajo</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="about-shape">
    <img src="../../../../assets/img/map-two.png" alt="map" />
  </div>
</div>
