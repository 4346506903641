<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <div class="footer-widget">
          <div class="footer-logo">
            <img src="../../../../assets/img/logo.png" alt="logo" />
          </div>
          <p>
            Nuestra premisa es que usted tenga el mejor servicio, por eso nos
            esforzamos en dar lo mejor de nosotros.
          </p>
          <div class="footer-link">
            <a routerLink="/">
              <img
                src="../../../../assets/img/google-play.png"
                alt="google image"
              />
            </a>
            <a routerLink="/">
              <img
                src="../../../../assets/img/app-store.png"
                alt="google image"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="footer-widget pl-90">
          <h3>Compañía</h3>
          <ul>
            <li>
              <a href="#"><i class="flaticon-right"></i> Anunciar</a>
            </li>
            <li>
              <a href="#"><i class="flaticon-right"></i> Soporte</a>
            </li>
            <li>
              <a href="#"><i class="flaticon-right"></i> Marketing</a>
            </li>
            <li>
              <a href="#"><i class="flaticon-right"></i> Prima</a>
            </li>
            <li>
              <a href="#"><i class="flaticon-right"></i> Contacto</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="footer-widget pl-90">
          <h3>Links rápidos</h3>
          <ul>
            <li>
              <span (click)="onClick('home')"
                ><i class="flaticon-right"></i> Home</span
              >
            </li>
            <li>
              <span (click)="onClick('about')"
                ><i class="flaticon-right"></i> Nosotros</span
              >
            </li>
            <li>
              <span (click)="onClick('services')"
                ><i class="flaticon-right"></i> Servicios</span
              >
            </li>
            <li>
              <span (click)="onClick('testimonials')"
                ><i class="flaticon-right"></i> Testimonios</span
              >
            </li>
            <li>
              <span (click)="onClick('pricing')"
                ><i class="flaticon-right"></i> Contacto</span
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="footer-widget pl-90">
          <h3>Redes sociales</h3>
          <ul>
            <li>
              <a href="#" target="_blank"
                ><i class="flaticon-right"></i> Facebook</a
              >
            </li>
            <li>
              <a href="#" target="_blank"
                ><i class="flaticon-right"></i> Twitter</a
              >
            </li>
            <li>
              <a href="#" target="_blank"
                ><i class="flaticon-right"></i> Linkedin</a
              >
            </li>
            <li>
              <a href="#" target="_blank"
                ><i class="flaticon-right"></i> Instagram</a
              >
            </li>
            <li>
              <a href="#" target="_blank"
                ><i class="flaticon-right"></i> Pinterest</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-4 lh-1">
          <img src="../../../../assets/img/visa.png" alt="visa card" />
          <img
            src="../../../../assets/img/american-express.png"
            alt="american-express"
          />
          <img src="../../../../assets/img/discover.png" alt="discover" />
          <img src="../../../../assets/img/master-card.png" alt="master card" />
        </div>
        <div class="col-lg-6 col-md-8 lh-1">
          <p>© MB RAIN GUTTER SYSTEMS, L.L.C</p>
        </div>
      </div>
    </div>
  </div>
</footer>
